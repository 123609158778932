// App Imports
import "@hotwired/turbo-rails";
import * as bootstrap from "bootstrap";

// jQuery Imports
window.jQuery = require("jquery");
require("jquery-ui-dist/jquery-ui");

// Controllers
import "./controllers";

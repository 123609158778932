// Imports
import { application } from "./application";

// Controllers
import ApplicationController from "./application_controller";
import HomeController from "./home_controller";

// Registers
application.register("application", ApplicationController);
application.register("home", HomeController);
